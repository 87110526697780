import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../utils/consts';

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  background: ${rgba(colors.accent, 0.5)};
  transform: rotate(3deg);
  transition: background-color ease-in-out 100ms;
  &:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: ${colors.accent};
    transform: rotate(4deg);
  }
  button {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    background: ${colors.primaryLight};
    border-radius: 1.44em;
    line-height: 2em;
    border: none;
    padding: 0 20px;
    margin: 0 -5px;
    color: ${colors.dark};
    font-family: Roboto;
    transform: rotate(-5deg);
    font-weight: bold;
    white-space: nowrap;
  }
`;

export default function Button({ children, ...attr }) {
  return (
    <ButtonWrapper>
      <button type="button" {...attr}>
        {children}
      </button>
    </ButtonWrapper>
  );
}
