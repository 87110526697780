import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaGithub, FaTwitter, FaEnvelope } from 'react-icons/fa';

import { InlineLink } from './Links';

const SocialContacts = ({ twitter, instagram, github }) => (
  <section>
    <p>
      <InlineLink href="mailo:info@matthiaswehnert.com">
        <FaEnvelope />
        {` `}info@matthiaswehnert.com
      </InlineLink>
    </p>
    <p>
      <InlineLink href="#">
        <FaTwitter /> {twitter}
      </InlineLink>
    </p>
    <p>
      <InlineLink href="#">
        <FaInstagram /> {instagram}
      </InlineLink>
    </p>
    <p>
      <InlineLink href="#">
        <FaGithub /> {github}
      </InlineLink>
    </p>
  </section>
);

export default SocialContacts;
