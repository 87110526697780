import React, { useRef } from 'react';
import { Link, graphql, StaticQuery } from 'gatsby';
import styled from 'styled-components';
import ReCAPTCHA from 'react-google-recaptcha';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Button from '../components/Button';
import { TeaserBox } from '../components/Teaser';
import SocialContacts from '../components/SocialContacts';
import PageTitle from '../components/PageTitle';

const ContactBody = styled.section`
  display: flex;
  flex-wrap: wrap;
  > * {
    flex: 1 0 300px;
  }

  aside {
    padding: 40px;
  }
`;

const RecaptchaContainer = styled.section`
  margin-top: -30px;
  > div {
    opacity: 0;
    visibility: hidden;
  }
  > small {
    font-size: 65%;
    a {
      display: inline;
    }
  }
`;

const FormInnerWrapper = styled.div`
  padding: 30px;
`;

function Contact(props) {
  const ReCAPTCHARef = useRef(null);

  return (
    <StaticQuery
      query={contactQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata;
        return (
          <Layout>
            <SEO
              title="Homepage"
              keywords={[`contact`, `gatsby`, `javascript`, `react`]}
            />
            <PageTitle title="Get in touch!" />
            <ContactBody>
              <form
                name="contact"
                method="POST"
                netlify-honeypot="bot-field"
                data-netlify-recaptcha="true"
                data-netlify="true"
                onSubmit={() => {
                  ReCAPTCHARef.current.execute();
                }}
              >
                <TeaserBox style={{ maxWidth: '500px' }}>
                  <FormInnerWrapper>
                    <p style={{ display: 'none' }}>
                      <label htmlFor="bot-field">
                        Don’t fill this out if you're human:
                      </label>
                      <input name="bot-field" id="bot-field" />
                    </p>
                    <p>
                      <label htmlFor="name">Your Name:</label>
                      <input type="text" name="name" id="name" />
                    </p>
                    <p>
                      <label htmlFor="email">Your Email:</label>
                      <input type="email" name="email" id="email" />
                    </p>
                    <p>
                      <label htmlFor="message">Message:</label>
                      <textarea name="message" id="message" />
                    </p>
                    <RecaptchaContainer>
                      <small>
                        This site is protected by reCAPTCHA and the Google
                        {` `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://policies.google.com/privacy"
                        >
                          Privacy Policy
                        </a>{' '}
                        and
                        {` `}
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://policies.google.com/terms"
                        >
                          Terms of Service
                        </a>{' '}
                        apply.
                      </small>
                      <ReCAPTCHA
                        size="invisible"
                        ref={ReCAPTCHARef}
                        // sitekey="6LfZCrsUAAAAAEISBoV1CApf1Chds5zWpH3Vc6H6"
                        sitekey="6LeQybsUAAAAAJcczlgMP4JBJlrFF9YZmKxF95Ct"
                      />
                    </RecaptchaContainer>
                    <div
                      style={{
                        position: 'absolute',
                        left: '50%',
                        bottom: 0,
                        transform: 'translate(-50%, 50%)',
                      }}
                    >
                      <Button type="submit">✉️ Send</Button>
                    </div>
                  </FormInnerWrapper>
                </TeaserBox>
              </form>
              <aside>
                <p>
                  If you want to reach out for me somewhere else, here are a
                  bunch of more regulary used social and dev networks. Of course
                  there is an email, too!
                </p>
                <SocialContacts {...social} />
              </aside>
            </ContactBody>
          </Layout>
        );
      }}
    />
  );
}

const contactQuery = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        author
        email
        social {
          twitter
          github
          instagram
        }
      }
    }
  }
`;

export default Contact;
