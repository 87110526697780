import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { rgba } from 'polished';
import Masonry from 'react-masonry-css';
import { colors } from '../utils/consts';

import { rhythm } from '../utils/typography';

import teaserBoxStyles from '../styles/TeaserBox.module.scss';
import Button from './Button';

import teaserRowStyles from '../styles/TeaserRow.module.scss';

export const TeaserSizer = styled.div`
  flex: 0 0 ${({ size }) => (size ? `${size * 100}%` : '33.33333%')};
`;

export function TeaserRow({ children }) {
  return <section className={teaserRowStyles.teaserRow}>{children}</section>;
}

const MasonryWrapper = styled.section`
  .masonry {
    display: flex;
    margin-left: -30px; /* gutter size offset */
    width: auto;
  }
  .masonry-column {
    padding-left: 15px; /* gutter size */
    background-clip: padding-box;
  }

  // Style your items
  .masonry-column > * {
    margin-bottom: 15px;
  }
`;

export function TeaserMasonry({ children }) {
  const breakpointColumnsObj = {
    default: 3,
    1000: 2,
    600: 1,
  };

  return (
    <MasonryWrapper>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="masonry"
        columnClassName="masonry-column"
      >
        {children}
      </Masonry>
    </MasonryWrapper>
  );
}

const BlogImageWrapper = styled.div`
  flex-basis: 300px;
  flex-grow: 1;
  flex-shrink: 1;
`;
const BlogTextWrapper = styled.div`
  padding: 30px;
  flex-grow: 1;
  flex-basis: 300px;
`;

const BlogContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  > div {
  }
`;

const TestIndication = styled.aside`
  position: absolute;
  top: 20px;
  right: -40px;
  transform: rotate(20deg);
  background: ${colors.accent};
  color: ${colors.dark};
  line-height: 1.2em;
  border-radius: 0.6em;
  padding: 0 5px;
  display: inline-blog;
  z-index: 1000;
  font-weight: bold;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0);
`;

export function BlogTeaserContent({ blogPost }) {
  return (
    <BlogContentWrapper key={blogPost.fields.slug}>
      {blogPost.frontmatter.featuredImage && (
        <BlogImageWrapper>
          <Img
            objectFit="cover"
            fluid={blogPost.frontmatter.featuredImage.childImageSharp.fluid}
            style={{ height: '100%' }}
          />
        </BlogImageWrapper>
      )}
      <BlogTextWrapper>
        <h3
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          <Link style={{ boxShadow: `none` }} to={blogPost.fields.slug}>
            {blogPost.frontmatter.title || blogPost.fields.slug}
          </Link>
        </h3>
        <small>{blogPost.frontmatter.date}</small>
        <p dangerouslySetInnerHTML={{ __html: blogPost.excerpt }} />
      </BlogTextWrapper>
    </BlogContentWrapper>
  );
}

const TeaserBoxContainer = styled.div`
  background: ${rgba(colors.primaryDark, 0.7)};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transform: rotate(3deg);
  margin: 20px;
`;

const TeaserBoxDiv = styled.div`
  position: relative;
  margin-bottom: 40px;
  background: ${rgba(colors.primaryLight, 0.9)};
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transform: rotate(-3deg);
  article {
    width: 100%;
    height: auto;
  }
`;

const BottomLinkWrapper = styled.div`
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  position: absolute;
`;

export function TeaserBox({
  children,
  bottomLinkTo,
  bottomLinkText,
  style,
  external,
}) {
  return (
    <TeaserBoxContainer>
      <TeaserBoxDiv>
        <article>{children}</article>
        {bottomLinkTo && (
          <BottomLinkWrapper>
            {!external ? (
              <Link className={teaserBoxStyles.bottomLink} to={bottomLinkTo}>
                <Button>{bottomLinkText}</Button>
              </Link>
            ) : (
              <a className={teaserBoxStyles.bottomLink} href={bottomLinkTo}>
                <Button>{bottomLinkText}</Button>
              </a>
            )}
          </BottomLinkWrapper>
        )}
      </TeaserBoxDiv>
    </TeaserBoxContainer>
  );
}
